<template>
	<b-card :title="`${$t('Material Name')}：Test`">
		<b-row v-if="activeData">
			<b-col class="p-1" cols="6" md="4" lg="3">
				<h6>{{$t('Material Size')}}</h6>
				<p>{{bytesToSize(activeData.size)}}</p>
			</b-col>
			<b-col class="p-1" cols="6" md="4" lg="3" v-if="activeData.type==1||activeData.type==3">
				<h6>{{$t('Duration')}}</h6>
				<p>{{activeData.hour}}s</p>
			</b-col>
			<b-col class="p-1" cols="6" md="4" lg="3">
				<h6>{{$t('Type')}}</h6>
				<p>{{typeList[activeData.type]}}</p>
			</b-col>
			<!-- <b-col class="p-1" cols="6" md="4" lg="3">
				<h6>{{$t('Uploader')}}</h6>
				<p>李明辉</p>
			</b-col> -->
			<b-col class="p-1" cols="6" md="4" lg="3">
				<h6>{{$t('Upload Time')}}</h6>
				<p>{{activeData.created_at}}</p>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import { BCard, BCardText, BRow, BCol,BButton, BFormInput } from 'bootstrap-vue'
import { getStorage, removeStorage,bytesToSize } from "@/libs/utils"
import config from "@/libs/config"

export default {
	components: {
		BCard,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput
	},
	
	data () {
		return {
			id: this.$route.query.id,
			activeData: {},
			typeList:['图片','视频','文本','音乐']
		}
	},
	created () {
		if(!this.id){
			this.$router.back()
		}
		const activeData = getStorage("ACTIVE_MATERIAL")
		if(!activeData){
			this.$router.back()
		}
		this.activeData = activeData
		
	},
	beforeDestroy() {
		removeStorage('ACTIVE_MATERIAL')
	},
	methods: {
		bytesToSize
	}
}
</script>

<style>

</style>
